import dayjs, { Dayjs } from 'dayjs'

export function formatDate(date: string | number | Date | Dayjs, format: string = 'MMM D, YYYY'): string {
  return dayjs(date).format(format)
}

export function formatDateTime(date: string | number | Date | Dayjs, format: string = 'MMM D, YYYY, hh:mm A'): string {
  return dayjs(date).format(format)
}

export function roundMinutes(date: Date | Dayjs): Dayjs {
  return dayjs(date)
    .startOf('hour')
    .add(dayjs(date).minute() >= 30 ? 1 : 0, 'hours')
}
